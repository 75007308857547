import React from "react"
import { Link } from "gatsby"
import StandardLayout from "../layouts/standard"
import coverImage from "../images/about/our-mission/our-mission-cover.jpg"
import PageCover from "../components/page-cover"
import pdf1 from "../assets/solid-catalogue.pdf"
import pdf2 from "../assets/stylam-exclusive.pdf"
import pdf3 from "../assets/elegant-series.pdf"
import pdf4 from "../assets/handpicked-series.pdf"
import pdfimage1 from "../assets/images/solid-catalogue.png"
import pdfimage2 from "../assets/images/stylam-exclusive.png"
import pdfimage3 from "../assets/images/elegant-series.png"
import pdfimage4 from "../assets/images/handpicked-series.png"

export default function Catalogues(props) {
  return (
    <StandardLayout title="Catalogues">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
        <div>E-Catalogues</div>
      </div>

      <div className="catalog-head">E-Catalogues</div>

      <div className="brch-cont">
        <div>
          <Link to="/catalogue/elegant-series">
            <img src={pdfimage1} alt="elegant series cover" />
          </Link>
        </div>
        <div className="center-childs">
          <Link to="/catalogue/elegant-series">
            <img src={pdfimage3} alt="elegant series cover" />
          </Link>
          <Link to="/catalogue/elegant-series">
            <img src={pdfimage3} alt="elegant series cover" />
          </Link>
          <Link to="/catalogue/elegant-series">
            <img src={pdfimage3} alt="elegant series cover" />
          </Link>
          <Link to="/catalogue/elegant-series">
            <img src={pdfimage3} alt="elegant series cover" />
          </Link>
        </div>
        <div>
          <Link to="/catalogue/elegant-series">
            <img src={pdfimage3} alt="elegant series cover" />
          </Link>
        </div>
      </div>
    </StandardLayout>
  )
}
